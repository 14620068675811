<template>
  <div
    ref="padding"
    class="padding"
  >
    <p class="date desktop">
      {{ date }}
    </p>
    <div
      class="line"
      :style="{ minHeight: styleHeight }"
    >
      <p class="date mobile">
        {{ date }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Padding',
  props: {
    days: {
      type: Number,
      default: 0,
    },
    startDate: {
      type: String,
      default: '',
    },
    scrollHeight: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    offset() {
      const height = this.days / 2;
      return height;
    },
    styleHeight() {
      return `${this.offset}px`;
    },
    date() {
      if (this.offset <= 40 || this.scrollHeight === 0) {
        return '';
      }

      if (!this.$refs.padding) {
        return '';
      }

      if (this.scrollHeight <= this.$refs.padding.offsetTop - 65) {
        return '';
      }

      const heightOffset = Math.ceil(this.scrollHeight - this.$refs.padding.offsetTop);

      const days = heightOffset * 2;

      return moment(this.startDate).add(days, 'days').format('MMMM Do, YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.padding {
  display: flex;

  .date {
    position: sticky;
    align-self: flex-start;
    vertical-align: top;
    opacity: 0.5;
    top: 70px;

    @media (min-width: $timeline-mobile-cutoff) {
      top: 16 5px;
    }
  }

  .line {
    padding-left: 2.5rem;
    position: relative;
    margin-left: 10px;

    @media (min-width: $timeline-mobile-cutoff) {
      margin-left: 0;
    }
  }
}
</style>
