<template>
  <div>
    <p
      v-for="(line, index) in body"
      :key="index"
    >
      <template v-if="typeof line === 'string'">
        {{ line }}
      </template>
      <template v-else-if="line.type === 'link'">
        <router-link
          class="link"
          :to="line.to"
          @click.enter="trackClick(line.name)"
        >
          {{ line.name }}
        </router-link>
      </template>
    </p>
  </div>
</template>

<script>

export default {
  name: 'EventBodyText',
  props: {
    body: {
      type: Array,
      required: true,
    },
  },
  methods: {
    trackClick(name) {
      this.$amplitude.track('Click Timeline Link', { name });
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  font-weight: 700;
  text-decoration: none;
}
</style>
