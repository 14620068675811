<template>
  <div class="calendar-event">
    <div class="row">
      <responsive-icon name="map" />
      <span
        v-if="mapsUrl"
        class="tooltip"
      >
        <a
          target="_blank"
          :href="mapsUrl"
        >{{ locationName }}</a>
        <span
          v-if="locationAddress"
          class="tooltipText"
        >{{ locationAddress }}</span>
      </span>
      <span v-else>{{ event.location }}</span>
    </div>
    <div class="row">
      <responsive-icon name="clock-outline" />
      <span>{{ time }}</span>
    </div>
    <div class="row">
      <span>{{ event.description }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import ResponsiveIcon from '@/components/ResponsiveIcon.vue';

export default {
  name: 'CalendarEvent',
  components: {
    ResponsiveIcon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locationName() {
      return this.event.location.split(',').shift().trim();
    },
    locationAddress() {
      return this.event.location.split(',').slice(1).join(',').trim();
    },
    mapsUrl() {
      if (!this.event.location.includes('9')) {
        return '';
      }
      return `https://www.google.com/maps/search/?api=1&query=${this.event.location}`;
    },
    time() {
      const start = moment(this.event.start).utcOffset('-07:00');
      const end = start.clone().add(...this.event.duration);

      return `${start.format('LT')} - ${end.format('LT')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-event {
  .row {
    display: flex;
    flex-direction: row;
    margin: 5px;

    span {
      margin-left: 5px;
    }
  }

  .add-to-calendar {
    margin-top: 2.5rem;
  }
}
</style>
