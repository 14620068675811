<template>
  <component
    :is="icon"
    :fill-color="color"
    class="icon"
  />
</template>

<script>
import AirplaneIcon from 'vue-material-design-icons/Airplane.vue';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft.vue';
import CalendarIcon from 'vue-material-design-icons/Calendar.vue';
import CatIcon from 'vue-material-design-icons/Cat.vue';
import CircleIcon from 'vue-material-design-icons/Circle.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import ClockOutlineIcon from 'vue-material-design-icons/ClockOutline.vue';
import EggFriedIcon from '@/components/icons/egg-fried.vue';
import GlassCocktail from 'vue-material-design-icons/GlassCocktail.vue';
import HandshakeIcon from 'vue-material-design-icons/Handshake.vue';
import HeartIcon from 'vue-material-design-icons/Heart.vue';
import MapIcon from 'vue-material-design-icons/Map.vue';
import RingIcon from 'vue-material-design-icons/Ring.vue';
import SilverwareForkKnife from 'vue-material-design-icons/SilverwareForkKnife.vue';
import TruckIcon from 'vue-material-design-icons/Truck.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ResponsiveIcon',
  components: {
    AirplaneIcon,
    ArrowLeftIcon,
    CalendarIcon,
    CatIcon,
    CircleIcon,
    CloseIcon,
    ClockOutlineIcon,
    EggFriedIcon,
    GlassCocktail,
    HandshakeIcon,
    HeartIcon,
    MapIcon,
    RingIcon,
    SilverwareForkKnife,
    TruckIcon,
  },
  props: {
    name: {
      type: String,
      default: 'circle',
    },
    size: {
      type: Number,
      default: 24,
    },
    staticColor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isDark: 'isDark',
    }),
    icon() {
      switch (this.name) {
        case 'airplane':
          return AirplaneIcon;
        case 'arrow-left':
          return ArrowLeftIcon;
        case 'calendar':
          return CalendarIcon;
        case 'cat':
          return CatIcon;
        case 'circle':
          return CircleIcon;
        case 'close':
          return CloseIcon;
        case 'clock-outline':
          return ClockOutlineIcon;
        case 'egg-fried':
          return EggFriedIcon;
        case 'glass-cocktail':
          return GlassCocktail;
        case 'hands':
          return HandshakeIcon;
        case 'heart':
          return HeartIcon;
        case 'map':
          return MapIcon;
        case 'ring':
          return RingIcon;
        case 'silverware-fork-knife':
          return SilverwareForkKnife;
        case 'truck':
          return TruckIcon;
        default:
          return CircleIcon;
      }
    },
    color() {
      if (this.isDark && !this.staticColor) {
        return '#ffffff';
      }

      return '#2c3e50';
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  height: 24px;
}
</style>
