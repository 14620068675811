<template>
  <div class="event">
    <div
      class="event-row"
      :class="event.hideCarrot && 'no-click'"
    >
      <p
        class="date desktop"
        @click="toggleShowEventBody"
      >
        {{ date }}
      </p>
      <div class="line">
        <responsive-icon
          :name="event.icon"
          class="marker"
          @click="toggleShowEventBody"
        />
        <p
          class="date mobile"
          @click="toggleShowEventBody"
        >
          {{ date }}
        </p>
        <div
          v-if="!event.hideCarrot"
          class="event-title"
          @click="toggleShowEventBody"
        >
          <p>{{ event.title }}</p>
          <ChevronUpIcon
            v-if="show && event.type !== 'none'"
            :size="36"
          />
          <ChevronDownIcon
            v-else-if="event.type !== 'none'"
            :size="36"
          />
        </div>
        <div
          v-else
          class="event-title"
        >
          <p>{{ event.title }}</p>
        </div>

        <section
          v-if="show"
          class="event-body"
        >
          <div
            v-if="event.type === 'text'"
            class="text"
          >
            <event-body-text
              v-if="event.body"
              :body="event.body"
            />
          </div>
          <div
            v-if="event.type === 'image'"
            class="image"
          >
            <event-body-text
              v-if="event.body"
              :body="event.body"
            />
            <progressive-img
              class="img"
              :placeholder="`/images-placeholder/${event.image}`"
              :src="`/images/${event.image}`"
            />
          </div>
          <div
            v-if="event.type === 'slide'"
            class="slide"
          >
            <event-body-text
              v-if="event.body"
              :body="event.body"
            />
            <slide-show
              :images="event.images"
              :title="event.title"
            />
          </div>
          <calendar-event
            v-if="event.type === 'calendar-event'"
            :event="event.event"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ResponsiveIcon from '@/components/ResponsiveIcon.vue';
import SlideShow from '@/components/SlideShow.vue';
import EventBodyText from '@/components/Timeline/EventBodyText.vue';
import CalendarEvent from '@/components/Timeline/CalendarEvent.vue';

export default {
  name: 'Event',
  components: {
    ResponsiveIcon,
    SlideShow,
    EventBodyText,
    CalendarEvent,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
  }),
  computed: {
    date() {
      if (this.event.date.length === 10) {
        // Dates without a timestamp should not get a timezone set.
        return moment(this.event.date).format('MMMM Do, YYYY');
      }
      return moment(this.event.date).utcOffset('-07:00').format('MMMM Do, YYYY');
    },
  },
  mounted() {
    if (this.event.open) {
      this.show = true;
    }
  },
  methods: {
    toggleShowEventBody() {
      if (this.event.type === 'none') {
        return;
      }
      this.show = !this.show;
      if (this.show) {
        this.$amplitude.track('Show Timeline Event Body', {
          title: this.event.title,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.event-row {
  cursor: pointer;
  display: flex;

  &.no-click {
    cursor: default;
  }

  .line {
    box-sizing: border-box;
    display: inline-block;
    padding-left: 2.5rem;
    position: relative;
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;

    @media (min-width: $timeline-mobile-cutoff) {
      margin-left: 0;
    }
  }

  .event-title {
    display: flex;
    width: 100%;

    p {
      margin: 5px 0;
      flex: 1;
      font-family: 'Raleway', sans-serif;
      font-size: 2.5rem;
    }
  }

  .marker {
    position: absolute;
    top: 10px;
    left: -15px;
    background: var(--background-color);
    height: 2.5rem;
    width: 2.5rem;
    padding: 2px 0;
  }

  .event-body {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    cursor: initial;

    .image {
      .img {
        width: 100%;
      }
    }
  }
}
</style>
