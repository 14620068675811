<template>
  <div class="timeline">
    <div
      v-for="(event, index) in timeline"
      :key="index"
    >
      <event :event="event" />
      <padding
        :days="nextLength(index)"
        :start-date="event.date"
        :scroll-height="scrollHeight"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import Event from '@/components/Timeline/Event.vue';
import Padding from '@/components/Timeline/Padding.vue';

export default {
  name: 'Timeline',
  components: {
    Event,
    Padding,
  },
  props: {
    timeline: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    scrollHeight: 0,
  }),
  mounted() {
    window.addEventListener('scroll', this.updateScrollHeight);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateScrollHeight);
  },
  methods: {
    nextLength(index) {
      if (this.timeline.length <= index + 1) {
        return 0;
      }
      const diff = moment(this.timeline[index + 1].date)
        .diff(moment(this.timeline[index].date));

      const duration = moment.duration(diff);

      return duration.asDays();
    },
    updateScrollHeight() {
      this.scrollHeight = window.scrollY;
    },
  },
};
</script>

<!-- eslint-disable-next-line vue-scoped-css/require-scoped -->
<style lang="scss">
.line {
  border-left: solid var(--primary-color) 6px;
  display: flex;
}

.date {
  box-sizing: border-box;
  display: none;
  opacity: 0.75;
  font-family: 'Cinzel', serif;
  margin-bottom: 0;

  &.desktop {
    width: $timeline-left-size;
    padding-right: 2.5rem;
    text-align: right;
    margin: 5px 0;
    line-height: 4rem;

    @media (min-width: $timeline-mobile-cutoff) {
      display: inline-block;
    }
  }

  &.mobile {
    display: inline-block;
    margin-top: 12px;
    line-height: 2.5rem;

    @media (min-width: $timeline-mobile-cutoff) {
      display: none;
    }
  }
}
</style>
